import React from 'react'

const BannerBlog= (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Socis</h1>
            </header>
            <div className="content">
                <p>Hem reunit institucions que treballen per oferir serveis de formació professional.</p>
            </div>
        </div>
    </section>
)

export default BannerBlog
